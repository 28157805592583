

/* Skip to content link */

a,
a:visited {
  color: var(--brandColor);
  text-decoration: none;
}

.skip-to-content {
  display: inline-block;
  padding: 0.5rem;
  position: absolute;
  top: -10rem;
  color: #fff;
  background-color: #000;
}

.skipt-to-content:focus {
  top: 0;
  z-index: 1;
}

[tabindex="-1"]:focus {
  outline: none;
}

nav ul {
  list-style-type: none;
  padding-left: 0;
}

nav .active {
  color: inherit;
  text-decoration: none;
  font-weight: bold;
}

@screen nav {
  body {
    background: linear-gradient(35deg, rgba(255,255,255,0) 10%, rgb(228, 235, 239) 100%);
    background-size: 100vw 100vh;
    background-attachment: fixed;
  }
}

@media (prefers-reduced-motion:no-preference) {
  :root {
    scroll-behavior: smooth
  }
}


/* Interactive map */

#location_map {
  width: 100% !important;
  height: 100% !important;
}

.visually-hidden { 
  position: absolute !important;
  height: 1px; 
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
}

.breadcrumb li {
  display: inline-block;
}

.breadcrumb li:not(:last-child):after  {
  display: inline-block;
  content: '/';
  margin: 0 0.25rem;
}


.anchor-link {
  @apply absolute right-0 p-2 opacity-0 bg-white rounded-sm;
}

@screen md {
  .anchor-link {
    @apply left-0 right-auto top-0 -ml-10 p-0 pr-2;
  }
}



h2:hover > .anchor-link,
h2 > .anchor-link:focus,
h3:hover > .anchor-link,
h3 > .anchor-link:focus {
  opacity: 0.5;
}

.pageIntro--clip {
  clip-path: polygon(0% 16%, 100% 0%, 100% 100%, 0% 100%);
}

@screen md {
  .pageIntro--clip {
    clip-path: polygon(4.15% 5.13%, 99.65% 10.10%, 100% 87.23%, 1.95% 92.85%);
    clip-path: polygon(4.15% 5.13%, 99.65% 10.1%, 99.58% 92.51%, 1.95% 99.3%);
  }
}

.video iframe {
  width: 100%;
  height: 100%;
}

.logo-compact img {
  width: auto;
  height: 100%;
  vertical-align: bottom;
}