/* 
  TYPOGRAPHY
*/

/* LOAD FONTS */

.proxima-nova-rg {
  font-family: proxima-nova, 'Proxima Nova', sans-serif;
  font-weight: 400;
  font-display: swap;
}

.proxima-nova-lt {
  font-family: proxima-nova, 'Proxima Nova', sans-serif;
  font-weight: 300;
  font-display: swap;
}

.proxima-nova-sb {
  font-family: proxima-nova, 'Proxima Nova', sans-serif;
  font-weight: 600;
  font-display: swap;
}

/* STYLING */

@screen nav {
  html {
    font-size: 106.25%;
    /* font-size: 112.5%; */
  }
}

body {
  font-family: proxima-nova, 'Proxima Nova', Arial, Helvetica, sans-serif;
  color: #222222;
}

h1 {
  font-family: proxima-nova, 'Proxima Nova', Arial, Helvetica, sans-serif;
  font-weight: 300;
}

h2, h3, h4, h5, h6,
.h2, .h3, .h4, .h5, .h6 {
  font-family: proxima-nova, 'Proxima Nova', Arial, Helvetica, sans-serif;
  font-weight: 400;
}

h1, .h1 { @apply text-4xl; }
h2, .h2 { @apply text-3xl leading-tight; }
h3, .h3 { @apply text-2xl leading-tight; }
h4, .h4 { @apply text-xl leading-tight; }
h5, .h5 { @apply text-lg; }

@screen nav {
  h1, .h1 { @apply text-5xl; }
  h2, .h2 { @apply text-4xl leading-tight; }
  h3, .h3 { @apply text-3xl leading-tight; }
  h4, .h4 { @apply text-2xl leading-tight; }
  h5, .h5 { @apply text-xl; }
}

ul {
  padding-left: 1rem;
}



.rte a,
.rte a:visited,
main a,
main a:visited {
  text-decoration: underline;
  color: blue;
  color: var(--brandColor);
}

.rte h2,
.rte h3 {
  @apply mt-3 mb-1;
}

.rte p,
.rte ul,
.rte ol {
  @apply mt-1 mb-3;
}

.rte {
  h4 {
    @apply mt-12 mb-3;
  }
  h5 {
    @apply mt-10 mb-2;
  }
  
  .heading-caption {
    @apply mb-2 text-gray-700;
    
    & + p,
    & + ul,
    & + ol {
      @apply mt-0;
    }
  }
  h4 + .heading-caption,
  h5 + .heading-caption {
    @apply -mt-1 text-sm;
  }
}
@screen md {
  .rte {
    h4 {
      @apply mt-12;
    }
    h4 + .heading-caption,
    h5 + .heading-caption {
      @apply -mt-2 text-sm;
    }
  }
}
.rte h2:first-child,
.rte h3:first-child,
.rte h4:first-child,
.rte h5:first-child,
.rte p:first-child,
.rte ul:first-child,
.rte ol:first-child {
  @apply mt-0;
}
.rte h2:last-child,
.rte h3:last-child,
.rte h4:last-child,
.rte h5:last-child,
.rte p:last-child,
.rte ul:last-child,
.rte ol:last-child {
  @apply mb-0;
}
