/* 
  Simple flexbox fallback for grid  
*/

@screen md {
  
  .grid-cols-2,
  .grid-cols-3,
  .Grid-mosaic {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
  }
  
  .grid-cols-2 > *,
  .grid-cols-3 > *,
  .Grid-mosaic li {
    flex-basis: calc((100% / 2) - 1rem);
    padding: 0 0.5rem;
    margin-bottom: 1rem;
  }

}


/* 
  If grid is supported, use the real Grid spec
*/

@supports (display: grid) {
  
  /* Remove flex layout attributes */
  .grid-cols-2,
  .grid-cols-3,
  .Grid-mosaic {
    margin: 0;
    display: grid;
  }
  
  .grid-cols-2 > *,
  .grid-cols-3 > *,
  .Grid-mosaic li {
    padding: 0;
    margin: 0;
  }

  @screen md {
    
    .Grid-default               { @apply grid grid-cols-12; }
    
    .Grid-container--body       { @apply grid col-start-2 col-end-11; }
    .Grid-container--bodyLarge  { @apply grid col-start-2 col-end-11; }
    .Grid-container--bodyLarger { @apply grid col-start-2 col-end-12; }
    .Grid-container--bodyFull   { @apply grid col-start-1 col-end-12; }
    .Grid-container--full       { @apply grid col-start-1 col-end-13; }

    .Grid-mosaic {
      display: grid;
      grid-template-columns: 1.35fr 0.35fr 1fr; 
      grid-template-rows: 1fr 1fr;
      grid-template-areas: 
      "main main second"
      "main main third"
      "fourth fifth fifth"
      ;
    }
    .Grid-mosaic--5 {
      grid-template-rows: 1fr 1fr 0.9fr;
    }
    
    .Grid-mosaic :nth-child(1) { grid-area: main; }
    .Grid-mosaic :nth-child(2) { grid-area: second; }
    .Grid-mosaic :nth-child(3) { grid-area: third; }
    
    .Grid-mosaic--5 :nth-child(4) { grid-area: fourth; }
    .Grid-mosaic--5 :nth-child(5) { grid-area: fifth; }
    .Grid-mosaic--5 :nth-child(4) img, 
    .Grid-mosaic--5 :nth-child(5) img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .Grid-mosaic > li,
    .Grid-mosaic div:not(.imageHasText-caption) {
      height: 100%;
    }
    .Grid-mosaic img {
      height: 100%;
      object-fit: cover;
    }
    
  }

}


.Grid-bookDirect {
  grid-template-areas: 
    'heading image'
    'caption caption'
    'cta cta';
  grid-template-columns: 1fr 30%;
  grid-template-rows: auto auto 1fr;
  place-items: end;
}


@screen md {
  .Grid-bookDirect {
    grid-template-areas: 
    'heading image'
    'caption image'
    'cta image';
    grid-template-columns: 1fr 28%;
    place-items: start;
  }
}

.Cell-bookDirect-image {
  grid-area: image;
}

.Cell-bookDirect-heading {
  grid-area: heading;
}

.Cell-bookDirect-caption {
  grid-area: caption;
}

.Cell-bookDirect-cta {
  grid-area: cta;
}