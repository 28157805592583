.imageHasText {
  position: relative;
}

.imageHasText-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #fff !important;
  background-color: #004053;
  background-color: rgba(1, 46, 60, 0.61);
  backdrop-filter: blur(7px);
  @apply px-2 py-1;
  z-index: 100;
}

.has-no-js .no-js-hidden {
  @apply hidden;
}

/* Custom caption styling for lightbox */
.pswp__custom-caption {
  color: #fff;
  width: calc(100% - 32px);
  max-width: 150em;
  padding: 2px 8px;
  position: absolute;
  left: 50%;
  bottom: 16px;
  text-align: center;
  transform: translateX(-50%);
}

.pswp__custom-caption a {
  color: #fff;
  text-decoration: underline;
}
