/* 
  ELements that start with a ,js- selector are for behavior purposes only.
  No styling here!
*/


/* Accessible toggle */

.js-collapse-toggle { display: none; }
.with-js .js-collapse-toggle { display: block; }
.with-js .js-collapse-block { display: none; }
[aria-expanded="true"] + .js-collapse-block { display: block; }

@screen nav {
  .with-js .js-nav\:collapse-expanded .js-collapse-toggle {
    display: none;
  }
  .with-js  .js-nav\:collapse-expanded .js-collapse-block {
    display: block;
  }
}

/* @media (min-width: theme.screens.nav) {
  .with-js .js-collapse-toggle { display: block; }
  .with-js .js-collapse-block { display: none; }
  [aria-expanded="true"] + .js-collapse-block { display: block; }
} */
