/**
 * Form styles
 */

label {
  display: block;
  @apply mb-1 text-lg;
}

input[type="text"],
input[type="email"],
select,
textarea {
  @apply p-1 text-lg w-full;

}

/**
 * Form Validation Errors
 */

input,
select {
  @apply mb-8;
}

textarea {
  @apply mb-6;
}

.error {
  @apply border border-solid border-red;
}

.error-message {
	color: red;
	font-style: italic;
  @apply pb-2 -mt-6;
  @apply text-sm;
}

/**
 * Spam Protection
 */
input#comment { display: none; }
