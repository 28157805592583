
.card--light p { @apply text-gray-600; }

.card--dark h2 a { @apply text-white; }
.card--dark p { color: rgba(255, 255, 255, 0.9); }


.js-card.card--light:hover p,
.js-card.card--light:focus-within p {
  @apply text-gray-700;
}

.js-card.card--light:hover h2 a,
.js-card.card--light:focus-within h2 a {
  @apply text-gray-700;
}

.card-hover img,
.js-card img {
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  transition: transform .25s ease-out;
}

.card-hover:hover img,
.js-card:hover img,
.js-card:focus-within img {
  transform: scale(1.03);
}

