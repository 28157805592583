/* Tabs */

.with-js .tabbed {
  margin: 1rem 0;
}

.with-js .tab-list {
  padding: 0;
}
.with-js .tab-list li {
  display: inline-block;
}

.with-js .tab-list a {
  display: inline-block;
  @apply px-3 py-2;
  text-decoration: none;
  color: var(--brandColor);
}

.with-js .tab-list a[aria-selected] {
  @apply text-gray-500;
  cursor: default;
  @apply bg-gray-100;
  /* background-color: transparent; */
}