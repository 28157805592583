.button {
  color: inherit;
  display: inline-block;
  text-decoration: none;
  @apply px-4 py-2;
}

.button:hover {
  @apply opacity-75;
}

.button-small { @apply px-3 py-1 text-sm };
.button-large { @apply px-5 py-2 };

button.unstyle {
  font-size: 100%;
  font-family: inherit;
  border: 0;
  padding: 0;
  background-color: transparent;
}