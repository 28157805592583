/* @import "tailwindcss/base"; */

/*
  CSS RESET
  Based on: https://hankchizljaw.com/wrote/a-modern-css-reset/
*/

/* Box sizing rules */

*,
*::before,
*::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e2e8f0;
}

/* Remove default padding */

ul {
  /* padding: 0; */
}

/* Remove default margin */

body,
h1,
h2,
h3,
h4,
p,
ul,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.4;
}

@media (min-width: 1024px) {
  body {
    line-height: 1.5;
  }
}

/* Remove list styles on ul, ol elements with a class attribute */

/* ul[class],
ol[class] {
  list-style: none;
  padding-left: 0;
} */

/* A elements that don't have a class get default styles */

a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}

/* Make images easier to work with */

img {
  /* max-width: 100%; */
  width: 100%;
  height: auto;
  display: block;
}

/* lazysizes auto width calculation */

picture img[data-sizes='auto'] {
  display: block;
  width: 100%;
}

picture img.w-auto[data-sizes='auto'] {
  width: auto;
}

/* Inherit fonts for inputs and buttons */

input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

[hidden] {
  display: none;
}

[hidden] + * {
  margin-top: 0!important
}

/* 
  TYPOGRAPHY
*/

/* LOAD FONTS */

.proxima-nova-lt {
  font-family: proxima-nova, 'Proxima Nova', sans-serif;
  font-weight: 300;
  font-display: swap;
}

/* STYLING */

@media (min-width: 1024px) {
  html {
    font-size: 106.25%;
    /* font-size: 112.5%; */
  }
}

body {
  font-family: proxima-nova, 'Proxima Nova', Arial, Helvetica, sans-serif;
  color: #222222;
}

h1 {
  font-family: proxima-nova, 'Proxima Nova', Arial, Helvetica, sans-serif;
  font-weight: 300;
}

h2, h3, h4, h5,
.h2, .h3, .h4, .h5 {
  font-family: proxima-nova, 'Proxima Nova', Arial, Helvetica, sans-serif;
  font-weight: 400;
}

h1, .h1 { font-size: 2.25rem;
}

h2, .h2 { font-size: 1.875rem; line-height: 1.25;
}

h3, .h3 { font-size: 1.5rem; line-height: 1.25;
}

h4, .h4 { font-size: 1.25rem; line-height: 1.25;
}

h5, .h5 { font-size: 1.125rem;
}

@media (min-width: 1024px) {
  h1, .h1 { font-size: 3rem;
  }

  h2, .h2 { font-size: 2.25rem; line-height: 1.25;
  }

  h3, .h3 { font-size: 1.875rem; line-height: 1.25;
  }

  h4, .h4 { font-size: 1.5rem; line-height: 1.25;
  }

  h5, .h5 { font-size: 1.25rem;
  }
}

ul {
  padding-left: 1rem;
}

.rte a,
.rte a:visited,
main a,
main a:visited {
  text-decoration: underline;
  color: blue;
  color: var(--brandColor);
}

.rte h2,
.rte h3 {
  margin-top: 0.75rem;
  margin-bottom: 0.25rem;
}

.rte p,
.rte ul,
.rte ol {
  margin-top: 0.25rem;
  margin-bottom: 0.75rem;
}

.rte h4 {
  margin-top: 3rem;
  margin-bottom: 0.75rem;
}

.rte h5 {
  margin-top: 2.5rem;
  margin-bottom: 0.5rem;
}

.rte .heading-caption {
  margin-bottom: 0.5rem;
  color: #4a5568;
}

.rte .heading-caption + p,
    .rte .heading-caption + ul,
    .rte .heading-caption + ol {
  margin-top: 0;
}

.rte h4 + .heading-caption,
  .rte h5 + .heading-caption {
  margin-top: -0.25rem;
  font-size: 0.875rem;
}

@media (min-width: 768px) {
  .rte h4 {
    margin-top: 3rem;
  }

  .rte h4 + .heading-caption,
    .rte h5 + .heading-caption {
    margin-top: -0.5rem;
    font-size: 0.875rem;
  }
}

.rte h2:first-child,
.rte h3:first-child,
.rte h4:first-child,
.rte h5:first-child,
.rte p:first-child,
.rte ul:first-child,
.rte ol:first-child {
  margin-top: 0;
}

.rte h2:last-child,
.rte h3:last-child,
.rte h4:last-child,
.rte h5:last-child,
.rte p:last-child,
.rte ul:last-child,
.rte ol:last-child {
  margin-bottom: 0;
}

/* 
  LAYOUTS
*/

.Lyt-base-container {
  max-width: 1000px;
}

@supports (display: grid) {
  .Lyt-base-container {
    max-width: 1600px;
    margin: auto;
  }
}

.Lyt-base-header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 110;
}

.Lyt-base-header-inner {
  display: flex;
  padding: 0.75rem;
}

.Lyt-base-body-inner {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

@media (min-width: 1024px) {
  .Lyt-base-container {
    display: flex;
    align-items: stretch;
  }

  .Lyt-base-header {
    flex-basis: 30%;
    flex-grow: 1;
    max-width: 280px;
    position: relative;
    top: auto;
    width: auto;
    background: transparent;
    /* background: linear-gradient(35deg, rgba(255,255,255,0) 20%, rgb(228, 235, 239) 100%); */
    /* background-attachment: fixed; */
    /* z-index: 110; */
  }

  @supports (display: grid) {
    .Lyt-base-header {
      flex-basis: 22%;
    }
  }

  .Lyt-base-header-inner {
    display: block;
    padding-top: 3rem;
    padding-right: 2.5rem;
    padding-left: 3rem;
  }

  @supports (display: grid) {
    .Lyt-base-header-inner {
      position: sticky;
      top: 0;
    }
  }

  .Lyt-base-body {
    flex-basis: 0;
    flex-grow: 999;
    background-color: #fff;
  }

  .Lyt-base-body-inner {
    padding-left: 3rem;
    padding-right: 4rem;
  }
}

.Lyt-body-bleed-l { margin-left: -0.75rem;
}

.Lyt-body-bleed-x { margin-left: -0.75rem; margin-right: -0.75rem;
}

@media (min-width: 768px) {
  .Lyt-body-bleed-l { margin-left: -0.75rem;
  }
}

@media (min-width: 1024px) {
  .Lyt-body-bleed-l { margin-left: -3rem;
  }

  .Lyt-body-bleed-x { margin-left: -3rem; margin-right: -4rem;
  }
}

/* 
  Simple flexbox fallback for grid  
*/

@media (min-width: 768px) {
  
  .Grid-mosaic {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
  }

  
  .Grid-mosaic li {
    flex-basis: calc(50% - 1rem);
    flex-basis: calc((100% / 2) - 1rem);
    padding: 0 0.5rem;
    margin-bottom: 1rem;
  }
}

/* 
  If grid is supported, use the real Grid spec
*/

@supports (display: grid) {
  /* Remove flex layout attributes */

  
  .Grid-mosaic {
    margin: 0;
    display: grid;
  }

  
  .Grid-mosaic li {
    padding: 0;
    margin: 0;
  }

  @media (min-width: 768px) {
    .Grid-default               { display: grid; grid-template-columns: repeat(12, minmax(0, 1fr));
    }

    .Grid-container--body       { display: grid; grid-column-start: 2; grid-column-end: 11;
    }

    .Grid-container--bodyLarge  { display: grid; grid-column-start: 2; grid-column-end: 11;
    }

    .Grid-container--bodyLarger { display: grid; grid-column-start: 2; grid-column-end: 12;
    }

    .Grid-container--full       { display: grid; grid-column-start: 1; grid-column-end: 13;
    }

    .Grid-mosaic {
      display: grid;
      grid-template-columns: 1.35fr 0.35fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas: 
      "main main second"
      "main main third"
      "fourth fifth fifth"
      ;
    }

    .Grid-mosaic--5 {
      grid-template-rows: 1fr 1fr 0.9fr;
    }

    .Grid-mosaic :nth-child(1) { grid-area: main;
    }

    .Grid-mosaic :nth-child(2) { grid-area: second;
    }

    .Grid-mosaic :nth-child(3) { grid-area: third;
    }

    .Grid-mosaic--5 :nth-child(4) { grid-area: fourth;
    }

    .Grid-mosaic--5 :nth-child(5) { grid-area: fifth;
    }

    .Grid-mosaic--5 :nth-child(4) img, 
    .Grid-mosaic--5 :nth-child(5) img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
    }

    .Grid-mosaic > li,
    .Grid-mosaic div:not(.imageHasText-caption) {
      height: 100%;
    }

    .Grid-mosaic img {
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
    }
  }
}

.Grid-bookDirect {
  grid-template-areas: 
    'heading image'
    'caption caption'
    'cta cta';
  grid-template-columns: 1fr 30%;
  grid-template-rows: auto auto 1fr;
  place-items: end;
}

@media (min-width: 768px) {
  .Grid-bookDirect {
    grid-template-areas: 
    'heading image'
    'caption image'
    'cta image';
    grid-template-columns: 1fr 28%;
    place-items: start;
  }
}

.Cell-bookDirect-image {
  grid-area: image;
}

.Cell-bookDirect-heading {
  grid-area: heading;
}

.Cell-bookDirect-caption {
  grid-area: caption;
}

/* Stacks */

[class^='stack'] > * {
  margin-top: 0;
  margin-bottom: 0;
}

.stack-small > * + * {
  margin-top: 0.5rem;
}

.stack-medium > * + * {
  margin-top: 1.5rem;
}

.stack-large > * + * {
  margin-top: 4rem;
}

/* Skip to content link */

a,
a:visited {
  color: var(--brandColor);
  text-decoration: none;
}

.skip-to-content {
  display: inline-block;
  padding: 0.5rem;
  position: absolute;
  top: -10rem;
  color: #fff;
  background-color: #000;
}

[tabindex="-1"]:focus {
  outline: none;
}

nav ul {
  list-style-type: none;
  padding-left: 0;
}

nav .active {
  color: inherit;
  text-decoration: none;
  font-weight: bold;
}

@media (min-width: 1024px) {
  body {
    background: linear-gradient(35deg, rgba(255,255,255,0) 10%, rgb(228, 235, 239) 100%);
    background-size: 100vw 100vh;
    background-attachment: fixed;
  }
}

@media (prefers-reduced-motion:no-preference) {
  :root {
    scroll-behavior: smooth
  }
}

/* Interactive map */

#location_map {
  width: 100% !important;
  height: 100% !important;
}

.breadcrumb li {
  display: inline-block;
}

.breadcrumb li:not(:last-child):after  {
  display: inline-block;
  content: '/';
  margin: 0 0.25rem;
}

.anchor-link {
  position: absolute;
  right: 0;
  padding: 0.5rem;
  opacity: 0;
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  border-radius: 0.125rem;
}

@media (min-width: 768px) {
  .anchor-link {
    left: 0;
    right: auto;
    top: 0;
    margin-left: -2.5rem;
    padding: 0;
    padding-right: 0.5rem;
  }
}

h2:hover > .anchor-link,
h2 > .anchor-link:focus,
h3:hover > .anchor-link,
h3 > .anchor-link:focus {
  opacity: 0.5;
}

.video iframe {
  width: 100%;
  height: 100%;
}

.logo-compact img {
  width: auto;
  height: 100%;
  vertical-align: bottom;
}

.imageHasText {
  position: relative;
}

.imageHasText-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #fff !important;
  background-color: #004053;
  background-color: rgba(1, 46, 60, 0.61);
  -webkit-backdrop-filter: blur(7px);
          backdrop-filter: blur(7px);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  z-index: 100;
}

.has-no-js .no-js-hidden {
  display: none;
}

/* Custom caption styling for lightbox */

.pswp__custom-caption {
  color: #fff;
  width: calc(100% - 32px);
  max-width: 150em;
  padding: 2px 8px;
  position: absolute;
  left: 50%;
  bottom: 16px;
  text-align: center;
  transform: translateX(-50%);
}

.pswp__custom-caption a {
  color: #fff;
  text-decoration: underline;
}

/* Lists */

.list-custom {
  list-style-type: none;
  padding-left: 0;
}

.list-custom > li {
  display: flex;
  align-items: flex-start;
  gap: 0.5em;
}

.list-custom > li:before {
  display: block;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  transform: translateY(0.15em);
  content: '';
  background: var(--list-icon);
  background-size: cover;
}

.list-checkmark {
  --list-icon: url('../icons/check-blue-artic.svg');
}

.list-checkmark > li:before {
  width: 1.15em;
  height: 1.15em;
}

.card--light p { color: #718096;
}

.card--dark h2 a { color: #fff;
}

.card--dark p { color: rgba(255, 255, 255, 0.9);
}

.js-card.card--light:hover p,
.js-card.card--light:focus-within p {
  color: #4a5568;
}

.js-card.card--light:hover h2 a,
.js-card.card--light:focus-within h2 a {
  color: #4a5568;
}

.card-hover img,
.js-card img {
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  transition: transform .25s ease-out;
}

.card-hover:hover img,
.js-card:hover img,
.js-card:focus-within img {
  transform: scale(1.03);
}

.button {
  color: inherit;
  display: inline-block;
  text-decoration: none;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.button:hover {
  opacity: 0.75;
}

.button-small { padding-left: 0.75rem; padding-right: 0.75rem; padding-top: 0.25rem; padding-bottom: 0.25rem; font-size: 0.875rem
};

.button-large { padding-left: 1.25rem; padding-right: 1.25rem; padding-top: 0.5rem; padding-bottom: 0.5rem
};

/* Tabs */

.with-js .tabbed {
  margin: 1rem 0;
}

.with-js .tab-list {
  padding: 0;
}

.with-js .tab-list li {
  display: inline-block;
}

.with-js .tab-list a {
  display: inline-block;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-decoration: none;
  color: var(--brandColor);
}

.with-js .tab-list a[aria-selected] {
  color: #a0aec0;
  cursor: default;
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
  /* background-color: transparent; */
}

/* 
  ELements that start with a ,js- selector are for behavior purposes only.
  No styling here!
*/

/* Accessible toggle */

.js-collapse-toggle { display: none;
}

.with-js .js-collapse-toggle { display: block;
}

.with-js .js-collapse-block { display: none;
}

[aria-expanded="true"] + .js-collapse-block { display: block;
}

@media (min-width: 1024px) {
  .with-js .js-nav\:collapse-expanded .js-collapse-toggle {
    display: none;
  }

  .with-js  .js-nav\:collapse-expanded .js-collapse-block {
    display: block;
  }
}

/* @media (min-width: theme.screens.nav) {
  .with-js .js-collapse-toggle { display: block; }
  .with-js .js-collapse-block { display: none; }
  [aria-expanded="true"] + .js-collapse-block { display: block; }
} */

/**
 * Form styles
 */

label {
  display: block;
  margin-bottom: 0.25rem;
  font-size: 1.125rem;
}

input[type="text"],
input[type="email"],
select,
textarea {
  padding: 0.25rem;
  font-size: 1.125rem;
  width: 100%;
}

/**
 * Form Validation Errors
 */

input,
select {
  margin-bottom: 2rem;
}

textarea {
  margin-bottom: 1.5rem;
}

.error {
  border-width: 1px;
  border-style: solid;
  border-color: #ff0000;
}

.error-message {
  color: red;
  font-style: italic;
  padding-bottom: 0.5rem;
  margin-top: -1.5rem;
  font-size: 0.875rem;
}

/**
 * Spam Protection
 */

input#comment { display: none;
}

/* 
  LIGHTBOX
*/

.iframe-container {
  width: 90vw;
  height: 89vh;
}

.lazyframe{
  position:relative;
  background-color:currentColor;
  background-repeat:no-repeat;
  background-size:cover;
  background-position:center
}

.lazyframe__title{
  position:absolute;
  top:0;
  right:0;
  left:0;
  padding:15px 17px;
  z-index:3
}

.lazyframe__title::after{
  z-index:-1
}

.lazyframe:hover{
  cursor:pointer
}

.lazyframe::before{
  display:block;
  content:"";
  width:100%;
  padding-top:100%
}

.lazyframe[data-ratio="16:9"]::before{
  padding-top:56.25%
}

.lazyframe[data-ratio="4:3"]::before{
  padding-top:75%
}

.lazyframe[data-ratio="1:1"]::before{
  padding-top:100%
}

.lazyframe iframe{
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  z-index:5;
  width:100%;
  height:100%
}

.lazyframe[data-vendor=youtube],.lazyframe[data-vendor=youtube_nocookie]{
  background-color:#e52d27;
  font-family:Roboto,Arial,Helvetica,sans-serif
}

.lazyframe[data-vendor=youtube] .lazyframe__title,.lazyframe[data-vendor=youtube_nocookie] .lazyframe__title{
  color:#eee;
  font-family:Roboto,Arial,Helvetica,sans-serif;
  font-size:18px;
  text-shadow:rgba(0,0,0,.498039) 0 0 2px;
  -webkit-font-smoothing:antialiased;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
  transition:color .1s cubic-bezier(0.4, 0, 1, 1)
}

.lazyframe[data-vendor=youtube] .lazyframe__title:hover,.lazyframe[data-vendor=youtube_nocookie] .lazyframe__title:hover{
  color:#fff
}

.lazyframe[data-vendor=youtube] .lazyframe__title::before,.lazyframe[data-vendor=youtube_nocookie] .lazyframe__title::before{
  content:"";
  display:block;
  background:linear-gradient(rgba(0, 0, 0, 0.2), transparent);
  height:98px;
  width:100%;
  pointer-events:none;
  position:absolute;
  top:0;
  left:0;
  right:0;
  z-index:-1;
  -webkit-tap-highlight-color:rgba(0,0,0,0)
}

.lazyframe[data-vendor=youtube]::before,.lazyframe[data-vendor=youtube_nocookie]::before{
  padding-top:56.25%
}

.lazyframe[data-vendor=youtube]::after,.lazyframe[data-vendor=youtube_nocookie]::after{
  content:"";
  position:absolute;
  left:50%;
  top:50%;
  width:68px;
  height:48px;
  margin-left:-34px;
  margin-top:-24px;
  background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%231F1F1F' d='M255.7 446.3c-53.3.3-106.6-.4-159.8-3.3-17.4-1-34.7-2.5-50.4-11C35 426.3 27 418.4 22 407.2 13.2 388.6 10.5 369 9 349c-3.4-41.3-3.6-82.6-1.8-123.8 1-22 1.6-44 6.8-65.5 2-8.4 5-16.6 8.8-24.4C32 117 48 108 67.3 104c16.2-3 32.8-3 49.3-3.7 56-2.3 112-3.5 168-3 43 .6 86.2 1.7 129.3 4 13.2.6 26.6.8 39.3 5.5 17.2 6.4 30 17.2 37 34.7 6.6 16.8 9.2 34.2 10.6 52 3.8 48.7 4 97.3.7 146-1 16.3-2.2 32.7-6.5 48.8-9.7 37-32.8 51.5-66.7 53.8-36.2 2.5-72.5 3.8-108.8 4.3-21.3.2-42.7 0-64 0zM203.2 344L348 264.7l-144.8-79.3V344z'/%3E%3Cpath fill='%23FEFDFD' d='M203.2 344V185.5L348 264.8 203.2 344z'/%3E%3C/svg%3E");
  background-position:center center;
  background-size:100%;
  background-repeat:no-repeat;
  opacity:.81;
  border:none;
  z-index:4
}

.lazyframe[data-vendor=youtube]:hover::after,.lazyframe[data-vendor=youtube_nocookie]:hover::after{
  background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23DD2C28' d='M255.7 446.3c-53.3.3-106.6-.4-159.8-3.3-17.4-1-34.7-2.5-50.4-11C35 426.3 27 418.4 22 407.2 13.2 388.6 10.5 369 9 349c-3.4-41.3-3.6-82.6-1.8-123.8 1-22 1.6-44 6.8-65.5 2-8.4 5-16.6 8.8-24.4C32 117 48 108 67.3 104c16.2-3 32.8-3 49.3-3.7 56-2.3 112-3.5 168-3 43 .6 86.2 1.7 129.3 4 13.2.6 26.6.8 39.3 5.5 17.2 6.4 30 17.2 37 34.7 6.6 16.8 9.2 34.2 10.6 52 3.8 48.7 4 97.3.7 146-1 16.3-2.2 32.7-6.5 48.8-9.7 37-32.8 51.5-66.7 53.8-36.2 2.5-72.5 3.8-108.8 4.3-21.3.2-42.7 0-64 0zM203.2 344L348 264.7l-144.8-79.3V344z'/%3E%3Cpath fill='%23FEFDFD' d='M203.2 344V185.5L348 264.8 203.2 344z'/%3E%3C/svg%3E");
  opacity:1
}

.lazyframe[data-vendor=vimeo]{
  background-color:#00adef
}

.lazyframe[data-vendor=vimeo] .lazyframe__title{
  font-family:"Helvetica Neue",Helvetica,Arial,sans-serif;
  color:#00adef;
  font-size:20px;
  font-weight:700;
  text-rendering:optimizeLegibility;
  -webkit-user-select:none;
     -moz-user-select:none;
          user-select:none;
  -webkit-font-smoothing:auto;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
  background-color:rgba(0,0,0,.5)
}

.lazyframe[data-vendor=vimeo]::before{
  padding-top:48.25%
}

.lazyframe[data-vendor=vimeo]::after{
  content:"";
  height:40px;
  width:65px;
  display:block;
  bottom:10px;
  left:10px;
  z-index:3;
  background-color:rgba(0,0,0,.5);
  background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' baseProfile='tiny' viewBox='0 0 24 24'%3E%3Cpath fill='%23FFF' d='M7.765 16.89l8.47-4.89-8.47-4.89'/%3E%3C/svg%3E");
  background-position:center center;
  background-size:100% 100%;
  background-repeat:no-repeat;
  border-radius:5px;
  position:relative
}

.lazyframe[data-vendor=vimeo]:hover::after{
  background-color:#00adef
}

.self-start {
  align-self: flex-start;
}

.self-center {
  align-self: center;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.bg-transparent {
  background-color: transparent;
}

.bg-black {
  --bg-opacity: 1;
  background-color: #000;
  background-color: rgba(0, 0, 0, var(--bg-opacity));
}

.bg-white {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
}

.bg-blue-artic-500 {
  --bg-opacity: 1;
  background-color: #71bcd0;
  background-color: rgba(113, 188, 208, var(--bg-opacity));
}

.bg-maadsBlue {
  --bg-opacity: 1;
  background-color: #004053;
  background-color: rgba(0, 64, 83, var(--bg-opacity));
}

.bg-gray-100 {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.bg-gray-200 {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.bg-gray-300 {
  --bg-opacity: 1;
  background-color: #e2e8f0;
  background-color: rgba(226, 232, 240, var(--bg-opacity));
}

.bg-gray-400 {
  --bg-opacity: 1;
  background-color: #cbd5e0;
  background-color: rgba(203, 213, 224, var(--bg-opacity));
}

.hover\:bg-gray-200:hover {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--gradient-color-stops));
}

.bg-opacity-25 {
  --bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bg-opacity: 0.5;
}

.hover\:bg-opacity-25:hover {
  --bg-opacity: 0.25;
}

.border-solid {
  border-style: solid;
}

.border-none {
  border-style: none;
}

.border-gray-200 {
  border-color: #edf2f7;
}

.border-gray-300 {
  border-color: #e2e8f0;
}

.border-gray-400 {
  border-color: #cbd5e0;
}

.border-gray-700 {
  border-color: #4a5568;
}

.rounded-sm {
  border-radius: 0.125rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.border-0 {
  border-width: 0;
}

.border {
  border-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.clear-both {
  clear: both;
}

.cursor-pointer {
  cursor: pointer;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.divide-gray-400 > :not(template) ~ :not(template) {
  border-color: #cbd5e0;
}

.divide-y > :not(template) ~ :not(template) {
  --divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--divide-y-reverse)));
  border-bottom-width: calc(1px * var(--divide-y-reverse));
}

.fill-current {
  fill: currentColor;
}

.flex-col {
  flex-direction: column;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-wrap {
  flex-wrap: wrap;
}

.float-right {
  float: right;
}

.text-xs {
  font-size: 0.75rem;
}

.text-sm {
  font-size: 0.875rem;
}

.text-base {
  font-size: 1rem;
}

.text-lg {
  font-size: 1.125rem;
}

.text-xl {
  font-size: 1.25rem;
}

.text-2xl {
  font-size: 1.5rem;
}

.text-3xl {
  font-size: 1.875rem;
}

.text-5xl {
  font-size: 3rem;
}

.italic {
  font-style: italic;
}

.font-thin {
  font-weight: 200;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-1 {
  grid-gap: 0.25rem;
  gap: 0.25rem;
}

.gap-2 {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.gap-3 {
  grid-gap: 0.75rem;
  gap: 0.75rem;
}

.gap-4 {
  grid-gap: 1rem;
  gap: 1rem;
}

.gap-5 {
  grid-gap: 1.25rem;
  gap: 1.25rem;
}

.gap-6 {
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.gap-8 {
  grid-gap: 2rem;
  gap: 2rem;
}

.col-gap-5 {
  grid-column-gap: 1.25rem;
  -moz-column-gap: 1.25rem;
       column-gap: 1.25rem;
}

.gap-x-2 {
  grid-column-gap: 0.5rem;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}

.gap-x-3 {
  grid-column-gap: 0.75rem;
  -moz-column-gap: 0.75rem;
       column-gap: 0.75rem;
}

.gap-x-4 {
  grid-column-gap: 1rem;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}

.row-gap-3 {
  grid-row-gap: 0.75rem;
  row-gap: 0.75rem;
}

.row-gap-12 {
  grid-row-gap: 3rem;
  row-gap: 3rem;
}

.gap-y-1 {
  grid-row-gap: 0.25rem;
  row-gap: 0.25rem;
}

.from-maadsBlue-800 {
  --gradient-from-color: #004053;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 64, 83, 0));
}

.to-maadsBlue-900 {
  --gradient-to-color: #013645;
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-24 {
  height: 6rem;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.leading-3 {
  line-height: .75rem;
}

.leading-4 {
  line-height: 1rem;
}

.leading-tight {
  line-height: 1.25;
}

.leading-snug {
  line-height: 1.375;
}

.list-none {
  list-style-type: none;
}

.list-disc {
  list-style-type: disc;
}

.tracking-wide {
  letter-spacing: 0.025em;
}

.m-2 {
  margin: 0.5rem;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.-mx-3 {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.-mx-5 {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-8 {
  margin-left: 2rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.ml-auto {
  margin-left: auto;
}

.-mt-12 {
  margin-top: -3rem;
}

.-mb-12 {
  margin-bottom: -3rem;
}

.-mt-px {
  margin-top: -1px;
}

.mt-2px {
  margin-top: 2px;
}

.max-h-full {
  max-height: 100%;
}

.max-h-screen {
  max-height: 100vh;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-xl {
  max-width: 36rem;
}

.object-contain {
  -o-object-fit: contain;
     object-fit: contain;
}

.object-cover {
  -o-object-fit: cover;
     object-fit: cover;
}

.opacity-75 {
  opacity: 0.75;
}

.hover\:opacity-75:hover {
  opacity: 0.75;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.object-contain {
  -o-object-fit: contain;
     object-fit: contain;
}

.object-cover {
  -o-object-fit: cover;
     object-fit: cover;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.p-0 {
  padding: 0;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-1\.2 {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.pl-0 {
  padding-left: 0;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.pl-1 {
  padding-left: 0.25rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pt-3 {
  padding-top: 0.75rem;
}

.pl-3 {
  padding-left: 0.75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-px {
  padding-top: 1px;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.space-y-1 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--space-y-reverse));
}

.space-y-2 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--space-y-reverse));
}

.space-y-3 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--space-y-reverse));
}

.stroke-current {
  stroke: currentColor;
}

.stroke-blue-artic {
  stroke: #71bcd0;
}

.stroke-2 {
  stroke-width: 2;
}

.transform {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
}

.translate-y-1 {
  --transform-translate-y: 0.25rem;
}

.-translate-y-1 {
  --transform-translate-y: -0.25rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-black {
  color: #000;
}

.text-white {
  color: #fff;
}

.text-blue-artic-500 {
  color: #71bcd0;
}

.text-blue-artic {
  color: #71bcd0;
}

.text-gray-300 {
  color: #e2e8f0;
}

.text-gray-600 {
  color: #718096;
}

.text-gray-700 {
  color: #4a5568;
}

.text-gray-800 {
  color: #2d3748;
}

.visited\:text-white:visited {
  color: #fff;
}

.visited\:text-blue-artic-500:visited {
  color: #71bcd0;
}

.visited\:text-gray-600:visited {
  color: #718096;
}

.visited\:text-gray-700:visited {
  color: #4a5568;
}

.visited\:text-gray-800:visited {
  color: #2d3748;
}

.hover\:text-white:hover {
  color: #fff;
}

.hover\:text-gray-300:hover {
  color: #e2e8f0;
}

.hover\:text-gray-600:hover {
  color: #718096;
}

.hover\:text-gray-800:hover {
  color: #2d3748;
}

.hover\:text-gray-900:hover {
  color: #1a202c;
}

.underline {
  text-decoration: underline;
}

.no-underline {
  text-decoration: none;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.whitespace-no-wrap {
  white-space: nowrap;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-10 {
  width: 2.5rem;
}

.w-20 {
  width: 5rem;
}

.w-auto {
  width: auto;
}

.w-1\/3 {
  width: 33.333333%;
}

.w-8\/12 {
  width: 66.666667%;
}

.w-full {
  width: 100%;
}

.z-50 {
  z-index: 50;
}

.z-100 {
  z-index: 100;
}

.z-200 {
  z-index: 200;
}

/*! PhotoSwipe main CSS by Dmytro Semenov | photoswipe.com */

.pswp {
  --pswp-bg: #000;
  --pswp-placeholder-bg: #222;
  --pswp-root-z-index: 100000;
  --pswp-preloader-color: rgba(79, 79, 79, 0.4);
  --pswp-preloader-color-secondary: rgba(255, 255, 255, 0.9);
  /* defined via js:
  --pswp-transition-duration: 333ms; */
  --pswp-icon-color: #fff;
  --pswp-icon-color-secondary: #4f4f4f;
  --pswp-icon-stroke-color: #4f4f4f;
  --pswp-icon-stroke-width: 2px;
  --pswp-error-text-color: var(--pswp-icon-color);
}

/*
	Styles for basic PhotoSwipe (pswp) functionality (sliding area, open/close transitions)
*/

.pswp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--pswp-root-z-index);
  display: none;
  touch-action: none;
  outline: 0;
  opacity: 0.003;
  contain: layout style size;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* Prevents focus outline on the root element,
  (it may be focused initially) */

.pswp:focus {
  outline: 0;
}

.pswp * {
  box-sizing: border-box;
}

.pswp img {
  max-width: none;
}

.pswp--open {
  display: block;
}

.pswp,
.pswp__bg {
  transform: translateZ(0);
  will-change: opacity;
}

.pswp__bg {
  opacity: 0.005;
  background: var(--pswp-bg);
}

.pswp,
.pswp__scroll-wrap {
  overflow: hidden;
}

.pswp__scroll-wrap,
.pswp__bg,
.pswp__container,
.pswp__item,
.pswp__content,
.pswp__img,
.pswp__zoom-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.pswp__img,
.pswp__zoom-wrap {
  width: auto;
  height: auto;
}

.pswp--click-to-zoom.pswp--zoom-allowed .pswp__img {
  cursor: zoom-in;
}

.pswp--click-to-zoom.pswp--zoomed-in .pswp__img {
  cursor: move;
  cursor: grab;
}

.pswp--click-to-zoom.pswp--zoomed-in .pswp__img:active {
  cursor: grabbing;
}

/* :active to override grabbing cursor */

.pswp--no-mouse-drag.pswp--zoomed-in .pswp__img,
.pswp--no-mouse-drag.pswp--zoomed-in .pswp__img:active,
.pswp__img {
  cursor: zoom-out;
}

/* Prevent selection and tap highlights */

.pswp__container,
.pswp__img,
.pswp__button,
.pswp__counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pswp__item {
  /* z-index for fade transition */
  z-index: 1;
  overflow: hidden;
}

.pswp__hidden {
  display: none !important;
}

/* Allow to click through pswp__content element, but not its children */

.pswp__content {
  pointer-events: none;
}

.pswp__content > * {
  pointer-events: auto;
}

/*

  PhotoSwipe UI

*/

/*
	Error message appears when image is not loaded
	(JS option errorMsg controls markup)
*/

.pswp__error-msg-container {
  display: grid;
}

.pswp__error-msg {
  margin: auto;
  font-size: 1em;
  line-height: 1;
  color: var(--pswp-error-text-color);
}

/*
class pswp__hide-on-close is applied to elements that
should hide (for example fade out) when PhotoSwipe is closed
and show (for example fade in) when PhotoSwipe is opened
 */

.pswp .pswp__hide-on-close {
  opacity: 0.005;
  will-change: opacity;
  transition: opacity var(--pswp-transition-duration) cubic-bezier(0.4, 0, 0.22, 1);
  z-index: 10; /* always overlap slide content */
  pointer-events: none; /* hidden elements should not be clickable */
}

/* class pswp--ui-visible is added when opening or closing transition starts */

.pswp--ui-visible .pswp__hide-on-close {
  opacity: 1;
  pointer-events: auto;
}

/* <button> styles, including css reset */

.pswp__button {
  position: relative;
  display: block;
  width: 50px;
  height: 60px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  cursor: pointer;
  background: none;
  border: 0;
  box-shadow: none;
  opacity: 0.85;
  -webkit-appearance: none;
  -webkit-touch-callout: none;
}

.pswp__button:hover,
.pswp__button:active,
.pswp__button:focus {
  transition: none;
  padding: 0;
  background: none;
  border: 0;
  box-shadow: none;
  opacity: 1;
}

.pswp__button:disabled {
  opacity: 0.3;
  cursor: auto;
}

.pswp__icn {
  fill: var(--pswp-icon-color);
  color: var(--pswp-icon-color-secondary);
}

.pswp__icn {
  position: absolute;
  top: 14px;
  left: 9px;
  width: 32px;
  height: 32px;
  overflow: hidden;
  pointer-events: none;
}

.pswp__icn-shadow {
  stroke: var(--pswp-icon-stroke-color);
  stroke-width: var(--pswp-icon-stroke-width);
  fill: none;
}

.pswp__icn:focus {
  outline: 0;
}

/*
	div element that matches size of large image,
	large image loads on top of it,
	used when msrc is not provided
*/

div.pswp__img--placeholder,
.pswp__img--with-bg {
  background: var(--pswp-placeholder-bg);
}

.pswp__top-bar {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  z-index: 10;
  /* allow events to pass through top bar itself */
  pointer-events: none !important;
}

.pswp__top-bar > * {
  pointer-events: auto;
  /* this makes transition significantly more smooth,
     even though inner elements are not animated */
  will-change: opacity;
}

/*

  Close button

*/

.pswp__button--close {
  margin-right: 6px;
}

/*

  Arrow buttons

*/

.pswp__button--arrow {
  position: absolute;
  top: 0;
  width: 75px;
  height: 100px;
  top: 50%;
  margin-top: -50px;
}

.pswp__button--arrow:disabled {
  display: none;
  cursor: default;
}

.pswp__button--arrow .pswp__icn {
  top: 50%;
  margin-top: -30px;
  width: 60px;
  height: 60px;
  background: none;
  border-radius: 0;
}

.pswp--one-slide .pswp__button--arrow {
  display: none;
}

/* hide arrows on touch screens */

.pswp--touch .pswp__button--arrow {
  visibility: hidden;
}

/* show arrows only after mouse was used */

.pswp--has_mouse .pswp__button--arrow {
  visibility: visible;
}

.pswp__button--arrow--prev {
  right: auto;
  left: 0px;
}

.pswp__button--arrow--next {
  right: 0px;
}

.pswp__button--arrow--next .pswp__icn {
  left: auto;
  right: 14px;
  /* flip horizontally */
  transform: scale(-1, 1);
}

/*

  Zoom button

*/

.pswp__button--zoom {
  display: none;
}

.pswp--zoom-allowed .pswp__button--zoom {
  display: block;
}

/* "+" => "-" */

.pswp--zoomed-in .pswp__zoom-icn-bar-v {
  display: none;
}

/*

  Loading indicator

*/

.pswp__preloader {
  position: relative;
  overflow: hidden;
  width: 50px;
  height: 60px;
  margin-right: auto;
}

.pswp__preloader .pswp__icn {
  opacity: 0;
  transition: opacity 0.2s linear;
  animation: pswp-clockwise 600ms linear infinite;
}

.pswp__preloader--active .pswp__icn {
  opacity: 0.85;
}

@keyframes pswp-clockwise {
  0% { transform: rotate(0deg);
  }

  100% { transform: rotate(360deg);
  }
}

/*

  "1 of 10" counter

*/

.pswp__counter {
  height: 30px;
  margin: 15px 0 0 20px;
  font-size: 14px;
  line-height: 30px;
  color: var(--pswp-icon-color);
  text-shadow: 1px 1px 3px var(--pswp-icon-color-secondary);
  opacity: 0.85;
}

.pswp--one-slide .pswp__counter {
  display: none;
}

.basicLightbox{
  position:fixed;
  display:flex;
  justify-content:center;
  align-items:center;
  top:0;
  left:0;
  width:100%;
  height:100vh;
  background:rgba(0,0,0,.8);
  opacity:.01;
  transition:opacity .4s ease;
  z-index:1000;
  will-change:opacity
}

.basicLightbox--visible{
  opacity:1
}

.basicLightbox__placeholder{
  max-width:100%;
  transform:scale(.9);
  transition:transform .4s ease;
  z-index:1;
  will-change:transform
}

.basicLightbox__placeholder>iframe:first-child:last-child,.basicLightbox__placeholder>img:first-child:last-child,.basicLightbox__placeholder>video:first-child:last-child{
  display:block;
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  margin:auto;
  max-width:95%;
  max-height:95%
}

.basicLightbox__placeholder>iframe:first-child:last-child,.basicLightbox__placeholder>video:first-child:last-child{
  pointer-events:auto
}

.basicLightbox__placeholder>img:first-child:last-child,.basicLightbox__placeholder>video:first-child:last-child{
  width:auto;
  height:auto
}

.basicLightbox--iframe .basicLightbox__placeholder,.basicLightbox--img .basicLightbox__placeholder,.basicLightbox--video .basicLightbox__placeholder{
  width:100%;
  height:100%;
  pointer-events:none
}

.basicLightbox--visible .basicLightbox__placeholder{
  transform:scale(1)
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .bg-blur {
    -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
  }
}

.aspect-ratio {
  height: 0;
  position: relative;
}

.aspect-ratio--16x9 { padding-bottom: 56.25%;
}

.aspect-ratio--4x3 {  padding-bottom: 75%;
}

.aspect-ratio--3x4 {  padding-bottom: 133.33%;
}

.aspect-ratio--1x1 {  padding-bottom: 100%;
}

.aspect-ratio--object {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.text-white-o-90 {
  color: #fff;
  color: rgba(255, 255, 255, 0.9);
}

.maadsBlue-o-70 {
  background-color: #194456;
  background-color: rgba(0,64,83,.7);
}

.maadsBlue-o-80 {
  background-color: #194456;
  background-color: rgba(0,64,83,.8);
}

.flex-basis-medium {
  flex-basis: 47%;
}

.scroll-mt-12 {
  scroll-margin-top: 3rem;
}

.stroke-2 {
  stroke-width: 2px;
}

.group:focus-within .group-focus-within\:inline-block {
  display: inline-block;
}

.text-balance {
  text-wrap: balance;
}

.scroll-mt-20 {
  scroll-margin-top: 5rem;
}

.scale-180 {
  transform: scale(1.8);
}

.bottom-10 {
  bottom: 10%;
}

.-right-3 {
  right: -0.75rem;
}

.-bottom-3 {
  bottom: -0.75rem;
}

.shrink-0 {
  flex-shrink: 0;
}

.drop-shadow-sm {
  filter: drop-shadow(0 1px 1px rgb(0 0 0 / 0.05));
}

@media (min-width: 768px) {

  .md\:items-center {
    align-items: center;
  }

  .md\:block {
    display: block;
  }

  .md\:inline-block {
    display: inline-block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:inline-flex {
    display: inline-flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:text-sm {
    font-size: 0.875rem;
  }

  .md\:text-base {
    font-size: 1rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
  }

  .md\:gap-1 {
    grid-gap: 0.25rem;
    gap: 0.25rem;
  }

  .md\:gap-4 {
    grid-gap: 1rem;
    gap: 1rem;
  }

  .md\:gap-12 {
    grid-gap: 3rem;
    gap: 3rem;
  }

  .md\:col-gap-6 {
    grid-column-gap: 1.5rem;
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem;
  }

  .md\:gap-x-4 {
    grid-column-gap: 1rem;
    -moz-column-gap: 1rem;
         column-gap: 1rem;
  }

  .md\:gap-x-12 {
    grid-column-gap: 3rem;
    -moz-column-gap: 3rem;
         column-gap: 3rem;
  }

  .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .md\:h-4 {
    height: 1rem;
  }

  .md\:h-6 {
    height: 1.5rem;
  }

  .md\:h-8 {
    height: 2rem;
  }

  .md\:h-auto {
    height: auto;
  }

  .md\:right-0 {
    right: 0;
  }

  .md\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mt-2 {
    margin-top: 0.5rem;
  }

  .md\:mt-4 {
    margin-top: 1rem;
  }

  .md\:mb-4 {
    margin-bottom: 1rem;
  }

  .md\:mt-6 {
    margin-top: 1.5rem;
  }

  .md\:mb-16 {
    margin-bottom: 4rem;
  }

  .md\:ml-auto {
    margin-left: auto;
  }

  .md\:-mt-16 {
    margin-top: -4rem;
  }

  .md\:max-w-sm {
    max-width: 24rem;
  }

  .md\:p-4 {
    padding: 1rem;
  }

  .md\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:pt-0 {
    padding-top: 0;
  }

  .md\:pt-4 {
    padding-top: 1rem;
  }

  .md\:pr-5 {
    padding-right: 1.25rem;
  }

  .md\:pl-5 {
    padding-left: 1.25rem;
  }

  .md\:pt-16 {
    padding-top: 4rem;
  }

  .md\:first\:pt-0:first-child {
    padding-top: 0;
  }

  .md\:last\:pt-0:last-child {
    padding-top: 0;
  }

  .md\:translate-y-1 {
    --transform-translate-y: 0.25rem;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-center {
    text-align: center;
  }

  .md\:text-right {
    text-align: right;
  }

  .md\:w-4 {
    width: 1rem;
  }

  .md\:w-6 {
    width: 1.5rem;
  }

  .md\:w-8 {
    width: 2rem;
  }

  .md\:w-2\/5 {
    width: 40%;
  }

  .md\:w-3\/5 {
    width: 60%;
  }

  .md\:aspect-ratio--16x9 { padding-bottom: 56.25%;
  }

  .md\:flex-basis-small {
    flex-basis: 23%;
  }
}

@media (min-width: 1024px) {

  .lg\:bottom-auto {
    bottom: auto;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:-ml-4 {
    margin-left: -1rem;
  }

  .lg\:-ml-12 {
    margin-left: -3rem;
  }

  .lg\:-mr-16 {
    margin-right: -4rem;
  }

  .lg\:relative {
    position: relative;
  }
}

@media (min-width: 1024px) {

  .nav\:bg-transparent {
    background-color: transparent;
  }

  .nav\:border-0 {
    border-width: 0;
  }

  .nav\:block {
    display: block;
  }

  .nav\:flex {
    display: flex;
  }

  .nav\:grid {
    display: grid;
  }

  .nav\:hidden {
    display: none;
  }

  .nav\:flex-col {
    flex-direction: column;
  }

  .nav\:text-sm {
    font-size: 0.875rem;
  }

  .nav\:text-base {
    font-size: 1rem;
  }

  .nav\:gap-2 {
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }

  .nav\:col-gap-3 {
    grid-column-gap: 0.75rem;
    -moz-column-gap: 0.75rem;
         column-gap: 0.75rem;
  }

  .nav\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .nav\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .nav\:mb-0 {
    margin-bottom: 0;
  }

  .nav\:ml-0 {
    margin-left: 0;
  }

  .nav\:ml-3 {
    margin-left: 0.75rem;
  }

  .nav\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .nav\:-ml-12 {
    margin-left: -3rem;
  }

  .nav\:-mr-16 {
    margin-right: -4rem;
  }

  .nav\:max-h-full {
    max-height: 100%;
  }

  .nav\:min-h-screen {
    min-height: 100vh;
  }

  .nav\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .nav\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .nav\:pt-0 {
    padding-top: 0;
  }

  .nav\:relative {
    position: relative;
  }

  .nav\:aspect-ratio--16x9 { padding-bottom: 56.25%;
  }

  .nav\:flow-2-cols {
    -moz-columns: 2 auto;
         columns: 2 auto;
    -moz-column-gap: 1rem;
         column-gap: 1rem;
  }
}

