/* Stacks */

[class^='stack'] > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .stack-small > * + * {
    @apply mt-2;
  }
  
  .stack-medium > * + * {
    @apply mt-6;
  }
  
  .stack-large > * + * {
    @apply mt-16;
  }