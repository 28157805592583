/* Lists */

.list-custom {
  @apply list-none pl-0;
  & > li {
    @apply flex items-start;
    gap: 0.5em;
  }
  & > li:before {
    @apply block flex-shrink-0;
    width: 1em;
    height: 1em;
    transform: translateY(0.15em);
    content: '';
    background: var(--list-icon);
    background-size: cover;
  }
}

.list-checkmark {
  --list-icon: url('../icons/check-blue-artic.svg');
  & > li:before {
    width: 1.15em;
    height: 1.15em;
  }
}

.list-minus {
  --list-icon: url('../icons/close-gray.svg');
}