/* 
  LAYOUTS
*/

.Lyt-base-container {
  max-width: 1000px;
}

@supports (display: grid) {
  .Lyt-base-container {
    max-width: 1600px;
    margin: auto;
  }
}

.Lyt-base-header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 110;
}

.Lyt-base-header-inner {
  display: flex;
  @apply p-3;
}
  
.Lyt-base-body-inner {
  @apply px-3;
}

@screen nav {
  .Lyt-base-container {
    display: flex;
    align-items: stretch;
  }
  .Lyt-base-header {
    flex-basis: 30%;
    flex-grow: 1;
    max-width: 280px;
    position: relative;
    top: auto;
    width: auto;
    background: transparent;
    /* background: linear-gradient(35deg, rgba(255,255,255,0) 20%, rgb(228, 235, 239) 100%); */
    /* background-attachment: fixed; */
    /* z-index: 110; */
  }
  @supports (display: grid) {
    .Lyt-base-header {
      flex-basis: 22%;
    }
  }
  .Lyt-base-header-inner {
    display: block;
    @apply pt-12 pr-10 pl-12;
  }
  @supports (display: grid) {
    .Lyt-base-header-inner {
      position: sticky;
      top: 0;
    }
  }
  .Lyt-base-body {
    flex-basis: 0;
    flex-grow: 999;
    background-color: #fff;
  }
  .Lyt-base-body-inner {
    @apply pl-12 pr-16;
  }
}

.Lyt-body-bleed-l { @apply -ml-3; }
.Lyt-body-bleed-r { @apply -mr-3; }
.Lyt-body-bleed-x { @apply -mx-3; }

@screen md {
  .Lyt-body-bleed-l { @apply -ml-3; }
  .Lyt-body-bleed-r { @apply -mr-3; }
}

@screen lg {
  .Lyt-body-bleed-l { @apply -ml-12; }
  .Lyt-body-bleed-r { @apply -mr-16; }
  .Lyt-body-bleed-x { @apply -ml-12 -mr-16; }
}